<template>
    <b-sidebar backdrop-variant="dark"
               backdrop
               right
               lazy
               shadow
               :visible="isOpen"
               @hidden="onHidden"
               width="450px">
        <template v-slot:title>
            <slot name="header">Ny position</slot>
        </template>
        <div class="px-2">
            <div class="card-body">
                <Loader v-if="isLoading" />
                <b-form @submit.prevent="onSubmit(false)">
                    <b-form-group id="input-group-number"
                                  label="Nummer"
                                  label-for="input-number">
                        <b-form-input id="input-number"
                                      v-model="form.concatenatedNumbers"
                                      type="number"
                                      min="0"
                                      step="0.01"
                                      placeholder="Ange nummer"
                                      :disabled="isSubmitting"
                                      autocomplete="off"
                                      required
                                      aria-describedby="input-number-help-block"
                                      @input="clearKeyInErrorMessages('Number')"/>
                        <b-form-text id="input-number-help-block">
                            <ul class="text-danger mb-0">
                                <li v-show="!isValidNumber">Numret används redan</li>
                                <li v-for="message in validationByKey('Number')"
                                    :key="message">
                                    {{ message }}
                                </li>
                            </ul>
                        </b-form-text>
                    </b-form-group>

                    <b-form-group id="input-group-article"
                                  label="Artikel"
                                  label-for="input-article">
                        <b-input-group>
                            <ArticleSelectList v-model="form.articleId"
                                               :disabled="isSubmitting"
                                               @update:serviceNeed="setServiceNeed"
                                               @update:article="setArticle"
                                               ref="saveBuildingPartPositionInputArticle" />
                        </b-input-group>

                        <b-form-text id="input-info-help-block">
                            <ul class="text-danger mb-0">
                                <li v-for="message in validationByKey('ArticleId')"
                                    :key="message">
                                    {{ message }}
                                </li>
                            </ul>
                        </b-form-text>
                    </b-form-group>

                    <b-form-group id="input-group-nextRevisionDate"
                                  label="Nästa revisionsdatum"
                                  label-for="input-nextRevisionDate">
                        <input id="input-nextRevisionDate"
                               v-model="form.nextRevisionDate"
                               type="date"
                               class="form-control"
                               :disabled="isSubmitting"
                               autocomplete="off"
                               aria-describedby="input-nextRevisionDate-help-block"
                               @blur="isValidDate" />
                        <b-form-text id="input-nextRevisionDate-help-block">
                            <ul class="text-danger mb-0">
                                <li v-for="message in validationByKey('NextRevisionDate')"
                                    :key="message">
                                    {{ message }}
                                </li>
                            </ul>
                        </b-form-text>
                    </b-form-group>

                    <b-form-group id="input-group-serviceNeed"
                                  label="Service"
                                  label-for="input-serviceNeed">
                        <b-form-checkbox id="input-serviceNeed"
                                         v-model="form.serviceNeed"
                                         :disabled="isSubmitting"
                                         aria-describedby="input-serviceNeed-help-block">
                            <ul class="text-danger mb-0">
                                <li v-for="message in validationByKey('ServiceNeed')"
                                    :key="message">
                                    {{ message }}
                                </li>
                            </ul>
                        </b-form-checkbox>
                    </b-form-group>

                    <b-form-group id="input-group-description"
                                  label="Beskrivning"
                                  label-for="input-description">
                        <b-form-textarea id="input-description"
                                         v-model="form.description"
                                         type="text"
                                         placeholder="Ange beskrivning"
                                         :disabled="isSubmitting"
                                         autocomplete="off"
                                         aria-describedby="input-description-help-block" />
                        <b-form-text id="input-description-help-block">
                            <ul class="text-danger mb-0">
                                <li v-for="message in validationByKey('Description')"
                                    :key="message">
                                    {{ message }}
                                </li>
                            </ul>
                        </b-form-text>
                    </b-form-group>

                    <b-form-group id="input-group-place"
                                  label="Plats"
                                  label-for="input-place">
                        <b-form-input id="input-place"
                                      v-model="form.place"
                                      type="text"
                                      placeholder="Ange plats"
                                      :disabled="isSubmitting"
                                      autocomplete="off"
                                      aria-describedby="input-place-help-block" />
                        <b-form-text id="input-place-help-block">
                            <ul class="text-danger mb-0">
                                <li v-for="message in validationByKey('Place')"
                                    :key="message">
                                    {{ message }}
                                </li>
                            </ul>
                        </b-form-text>
                    </b-form-group>

                    <b-form-group label="Scheman">
                        <b-form-checkbox-group v-model="form.selectedSchedules"
                                               :options="allSchedules"
                                               stacked></b-form-checkbox-group>
                    </b-form-group>

                    <b-alert :show="validationByKey('Other').length > 0"
                             variant="danger">
                        <ul class="text-danger mb-0">
                            <li v-for="message in validationByKey('Other')"
                                :key="message">
                                {{ message }}
                            </li>
                        </ul>
                    </b-alert>

                    <b-alert :show="successMessage.length > 0"
                             variant="success"
                             class="mb-0 mt-4">
                        <h6 class="mb-0 text-center">
                            <font-awesome-icon icon="info-circle" /> {{ successMessage }}
                        </h6>
                    </b-alert>

                    <b-row align-content="between" class="mt-3">
                        <b-col cols="12">
                            <b-button v-b-tooltip.hover
                                      block
                                      variant="primary"
                                      :disabled="isSubmitting || !isValidNumber"
                                      @click="onSubmit(true)"
                                      :title="isSubmitting ? 'Sparar...' : ''">
                                <font-awesome-icon v-if="isSubmitting"
                                                   icon="spinner"
                                                   spin />
                                Spara
                            </b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </div>
        </div>
    </b-sidebar>
</template>

<script>
    import { post, get, } from '@/helpers/api';
    import Loader from '@/components/Loader';
    import ArticleSelectList from '@/components/article/ArticleSelectList';
    import {
        isValidDate
    } from '@/helpers/date.helper';

    export default {
        name: 'AddPosition',
        components: {
            Loader,
            ArticleSelectList,
        },
        props: {
            position: {
                type: Object,
                default: () => ({}),
            },
            isOpen: {
                type: Boolean,
                default: false,
            },
            positions: {
                type: Array,
                default: [],
            },
            controlId: {
                type: Number,
                default: null,
            },
            controlPartId: {
                type: Number,
                default: null,
            },
            partId: {
                type: Number,
                default: null,
            },
            number: {
                type: Number,
                default: null,
            },
            buildingId: {
                type: Number,
                default: null,
            },
            scheduleId: {
                type: Number,
                default: null,
            },
        },
        data() {
            return {
                isLoading: false,
                form: {
                    id: 0,
                    articleId: null,
                    displayNumber: 0,
                    concatenatedNumbers: 0,
                    description: '',
                    place: null,
                    partId: this.partId,
                    controlPartId: this.controlPartId,
                    serviceNeed: false,
                    nextRevisionDate: null,
                    coordinateX: this.position.coordinateX,
                    coordinateY: this.position.coordinateY,
                    coordinateInfoX: this.position.coordinateInfoX,
                    coordinateInfoY: this.position.coordinateInfoY,
                    selectedSchedules: [],
                },
                allSchedules: [],
                isSubmitting: false,
                successMessage: '',
                validationErrors: null
            };
        },
        computed: {
            isValidNumber() {
                return !this.positions.some(
                    (x) =>
                        parseFloat(x.concatenatedNumbers) ===
                        parseFloat(this.form.concatenatedNumbers) && x.id !== this.form.id // Exclude current position.
                );
            },
        },
        watch: {
            isOpen: {
                async handler(newValue) {
                    if (newValue) {
                        this.form.concatenatedNumbers = this.nextPartPositionNumber();

                        await this.getSchedules();
                        if (this.scheduleId)
                            this.form.selectedSchedules.push(this.scheduleId);
                    }
                },
                immediate: true
            }
        },
        methods: {
            clearKeyInErrorMessages(key) {
                if (this.validationErrors !== null) {
                    this.$delete(this.validationErrors, key);
                    if (Object.keys(this.validationErrors).length === 0) {
                        this.validationErrors = null;
                    }
                }
            },
            async getSchedules() {
                await get(
                    'BuildingSchedule',
                    `GetAllSimpleByBuildingId/${this.buildingId}`
                ).then((x) => {
                    this.allSchedules = x.data.map((x) => {
                        return { text: x.name, value: x.id };
                    });
                });
            },
            nextPartPositionNumber() {
                if (this.number) {
                    const subNumber = this.nextPartPositionSubNumber();
                    
                    return `${this.number}${(subNumber ? '.' + subNumber : '')}`;
                }


                const highestNumber = this.positions.reduce((entity, max) => {
                    const parsed = parseInt(parseFloat(max.concatenatedNumbers).toFixed(0));
                    if (entity > parsed) {
                        return entity;
                    } else {
                        return parsed;
                    }
                }, 0);

                return highestNumber + 1;
            },
            nextPartPositionSubNumber() {
                const highestNumber = this.positions.filter(x => x.number === this.number).reduce((entity, max) => {
                    const parsed = parseInt(parseFloat(max.subNumber).toFixed(0));
                    if (entity > parsed) {
                        return entity;
                    } else {
                        return parsed;
                    }
                }, 0);

                if (isNaN(highestNumber))
                    return 1;

                return highestNumber + 1;
            },
            isValidDate(event) {
                const value = event.currentTarget.value;
                if (value !== '') {
                    if (!isValidDate(value)) {
                        return (this.validationErrors = {
                            NextRevisionDate: ['Felaktigt datum']
                        });
                    }
                }

                // Show browser custom message if date match regex.
                if (event.currentTarget.validationMessage.length > 0) {
                    return (this.validationErrors = {
                        NextRevisionDate: [event.currentTarget.validationMessage]
                    });
                }

                // Sets value to null.
                if (value === '') {
                    this.form.nextRevisionDate = null;
                }

                // Remove error message if empty date.
                if (this.validationErrors !== null) {
                    this.$delete(this.validationErrors, 'NextRevisionDate');
                    if (Object.keys(this.validationErrors).length === 0)
                        this.validationErrors = null;
                    return;
                }
            },
            async onSubmit() {
                // Prevent form send if incorrect date.
                if (this.validationErrors !== null) {
                    if (typeof this.validationErrors['NextRevisionDate'] !== 'undefined')
                        return false;
                }

                // Resets.
                this.successMessage = '';
                this.validationErrors = null;
                this.isSubmitting = true;

                // Post.
                try {
                    await post('Control', `AddPosition/${this.controlId}`, {
                        ...this.form,
                        concatenatedNumbers: this.form.concatenatedNumbers.toString()
                    }).then((x) => {
                        this.form.id = parseInt(x.data.entityId);

                        this.hasSavedSuccessfullly = true;
                        this.successMessage = `Sparade ${this.form.description}`;
                        this.isSubmitting = false;

                        this.close(x.data.entityId);
                    });

                    return true;
                } catch (ex) {
                    this.validationErrors = ex.response.data.errors;
                    this.isSubmitting = false;
                    return false;
                }
            },
            onHidden() {
                this.close();
            },
            close(addedControlPartPositionId) {
                this.resetForm();
                this.$emit('close', addedControlPartPositionId);
            },
            validationByKey(code) {
                return this.validationErrors ? this.validationErrors[code] || [] : [];
            },
            resetForm() {
                this.form = {
                    id: 0,
                    articleId: null,
                    concatenatedNumbers: null,
                    description: '',
                    place: null,
                    partId: this.partId,
                    controlPartId: this.controlPartId,
                    serviceNeed: false,
                    nextRevisionDate: null,
                    coordinateX: null,
                    coordinateY: null,
                    coordinateInfoX: null,
                    coordinateInfoY: null,
                    selectedSchedules: [],
                };
            },
            /* Emits */
            setServiceNeed(val) {
                this.form.serviceNeed = val;
            },
            setArticle(val) {
                this.form.articleName = val.name;
                this.form.articleIcon = val.icon;
            }
        }
    };
</script>

<style scoped>
    .form-group {
        margin-bottom: 0.5rem;
    }
</style>
