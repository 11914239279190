<template>
    <b-sidebar backdrop-variant="dark"
               backdrop
               right
               lazy
               shadow
               :visible="isOpen"
               @hidden="onHidden"
               width="450px">
        <template v-slot:title>
            <slot name="header">Lägg till del</slot>
        </template>
        <div class="px-2">
            <div class="card-body">
                <Loader v-if="isLoading" />
                <b-form @submit.prevent="onSubmit(false)"
                        v-else>
                    <b-form-group label="">
                        <b-form-checkbox-group v-model="selectedParts"
                                               :options="allParts"
                                               stacked></b-form-checkbox-group>
                    </b-form-group>

                    <b-alert :show="validationByKey('Other').length > 0"
                             variant="danger">
                        <ul class="text-danger mb-0">
                            <li v-for="message in validationByKey('Other')"
                                :key="message">
                                {{ message }}
                            </li>
                        </ul>
                    </b-alert>

                    <b-alert :show="allParts.length === 0"
                             variant="info"
                             class="mb-0">
                        <h6 class="mb-0 text-center">
                            <font-awesome-icon icon="info-circle" /> Det finns inga fastghetsdelar att lägga till
                        </h6>
                    </b-alert>

                    <b-row align-content="between" class="mt-3" 
                           v-if="allParts.length !== 0">
                        <b-col cols="12">
                            <b-button v-b-tooltip.hover
                                      block
                                      variant="primary"
                                      :disabled="isSubmitting"
                                      @click="onSubmit(true)"
                                      :title="isSubmitting ? 'Lägger till...' : ''">
                                <font-awesome-icon v-if="isSubmitting"
                                                   icon="spinner"
                                                   spin />
                                Lägg till
                            </b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </div>
        </div>
    </b-sidebar>
</template>

<script>
    import { post, get, } from '@/helpers/api';
    import Loader from '@/components/Loader';

    export default {
        name: 'AddPart',
        components: {
            Loader,
        },
        props: {
            isOpen: {
                type: Boolean,
                default: false,
            },
            buildingId: {
                type: Number,
            },
            partsInUse: {
                type: Array,
            },
            controlId: {
                type: Number,
            },
        },
        data() {
            return {
                isLoading: true,
                isSubmitting: false,
                validationErrors: null,
                allParts: [],
                selectedParts: [],
            };
        },
        watch: {
            isOpen: {
                async handler(newValue) {
                    if (newValue) {
                        await this.getParts();
                    }
                },
                immediate: true
            }
        },
        methods: {
            async getParts() {
                await get(
                    'BuildingPart',
                    `GetAllByBuildingId/${this.buildingId}`
                ).then((x) => {
                    this.isLoading = false;
                    this.allParts = x.data
                        .map(part => {
                            if (!this.partsInUse.find(inUsePart => inUsePart.partId === part.id)) {
                                return { text: part.name, value: part.id };
                            }
                        })
                        .filter(part => part);

                }).catch(() => {
                    this.isLoading = false;
                });
            },
            async onSubmit() {
                // Resets.
                this.validationErrors = null;
                this.isSubmitting = true;

                // Post.
                try {
                    await post('Control', `AddParts/${this.controlId}`, this.selectedParts).then(() => {
                        this.isSubmitting = false;
                        this.close(true);
                    });

                    return true;
                } catch (ex) {
                    this.validationErrors = ex.response.data.errors;
                    this.isSubmitting = false;
                }
            },
            onHidden() {
                this.close();
            },
            close(added) {
                this.resetForm();
                this.$emit('close', added);
            },
            validationByKey(code) {
                return this.validationErrors ? this.validationErrors[code] || [] : [];
            },
            resetForm() {
                this.selectedParts = [];
            },
        }
    };
</script>

<style scoped>
    .form-group {
        margin-bottom: 0.5rem;
    }
</style>
